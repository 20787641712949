import React from 'react'
import { AiOutlineMail } from 'react-icons/ai';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';
// import { HiOutlineChevronDoubleUp } from 'react-icons/hi';
// import { Link } from 'react-scroll';
import contact from '../assets/contact.jpg'
const Contact = () => {
  return (
    <div id='contact' className='w-full lg:h-auto text-[#262629]'>
    <div className='max-w-[1240px] m-auto px-2 py-16 w-full '>
      <h1 className='text-xl tracking-widest uppercase text-[#5651e5]'>
        Contact
      </h1>
      <h2 className='py-4'>Get In Touch</h2>
      <div className='grid lg:grid-cols-5 gap-8'>
        {/* left */}
        <div className='col-span-3 lg:col-span-2 w-full h-full shadow-xl shadow-gray-400 rounded-xl p-4'>
          <div className='lg:p-4 h-full '>
            <div>
            <img className='rounded-xl hover:scale-105 ease-in duration-300' src={contact} alt='/' />
            </div>
            <div>
              <h2 className='py-2'>Touch I.T</h2>
              
              <p className='py-4'>
                we are available for freelance or full-time positions. Contact
                us and let&apos;s talk.
              </p>
            </div>
            <div>
              <p className='uppercase pt-8'>Connect With us</p>
              <div className='flex items-center justify-between py-4'>
               
                  <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                    <FaLinkedinIn />
                  </div>
               
                  <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                    <FaGithub />
                  </div>
                

                <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                  <AiOutlineMail />
                </div>
                {/* <Link href='/resume'> */}
                  
                    <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                      <BsFillPersonLinesFill />
                    </div>
                  
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>

        {/* right */}
        <div className='col-span-3 w-full h-auto shadow-xl shadow-gray-400 rounded-xl lg:p-4'>
          <div className='p-4'>
            <form
              action='https://getform.io/f/08ebcd37-f5b5-45be-8c13-714f011ce060'
              method='POST'
              encType='multipart/form-data'
            >
              <div className='grid w-full py-2'>
                <div className='flex flex-col'>
                  <label className='uppercase text-sm py-2'>Name</label>
                  <input
                    className='border-2 rounded-lg p-3 flex border-gray-300'
                    type='text'
                    name='name'
                  />
                </div>
                {/* <div className='flex flex-col'>
                  <label className='uppercase text-sm py-2'>
                    Phone Number
                  </label>
                  <input
                    className='border-2 rounded-lg p-3 flex border-gray-300'
                    type='text'
                    name='phone'
                  />
                </div> */}
              </div>
              <div className='flex flex-col py-2'>
                <label className='uppercase text-sm py-2'>Email</label>
                <input
                  className='border-2 rounded-lg p-3 flex border-gray-300'
                  type='email'
                  name='email'
                />
              </div>
              <div className='flex flex-col py-2'>
                <label className='uppercase text-sm py-2'>Subject</label>
                <input
                  className='border-2 rounded-lg p-3 flex border-gray-300'
                  type='text'
                  name='subject'
                />
              </div>
              <div className='flex flex-col py-2'>
                <label className='uppercase text-sm py-2'>Message</label>
                <textarea
                  className='border-2 rounded-lg p-3 border-gray-300'
                  rows='10'
                  name='message'
                ></textarea>
              </div>
              <button className='w-full p-4 mt-4 -mb-52 text-blue-700'>
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className='flex justify-center py-12'>
        
{/*           
            <div className='rounded-full shadow-lg shadow-gray-400 p-4 cursor-pointer hover:scale-110 ease-in duration-300'>
            <Link to="home" smooth={true} offset={50} duration={500} ><HiOutlineChevronDoubleUp
                className='text-[#5651e5]'
                size={30} /></Link> 
              
            </div> */}
          
        {/* </Link> */}
      </div>
    </div>
  </div>
  )
}

export default Contact
