import About from "./components/about";
import Choose from "./components/choose";
import Contact from "./components/contact";
import Home from "./components/home";
import Navbar from "./components/navbar";
import Service from "./components/service";
import { BrowserRouter } from "react-router-dom";
import Teammember from "./components/teammember";
import Footer from "./components/footer";
import React, { useEffect } from "react";
// import Team from "./components/team";

function App() {
  useEffect(() => {
    document.title = "Touch IT";
  }, []);
  return (
    <BrowserRouter>
      <Navbar />
      <Home />
      <Service />
      <About />
      <Choose />
      <Teammember />
      {/* <Team /> */}
      <Contact />
      <Footer />
      {/* <Name/> */}
    </BrowserRouter>
  );
}

export default App;
