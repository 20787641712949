import React from "react";
import browser from "../assets/browser.png";
import web from "../assets/web.png";
import mobile from "../assets/mobile.png";

const Service = () => {
  return (
    <div id="services" className="text-center m-2 mt-10 pt-20">
      <h1 className="text-gray-700 tracking-widest pt-3">
        Our <span className="text-blue-800">Services</span>{" "}
      </h1>
      <div className="w-full py-[10rem] px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-10">
          <div className="w-full shadow-xl flex-col p-4 rounded-lg hover:scale-105 hover:bg-sky-950 hover:text-white duration-300">
            <img className="w-20 mx-auto mt-[-3rem]" src={browser} alt="/" />
            <h2 className="text-2xl font-bold text-center py-2">API</h2>
            <p className="text-center text-xl font-bold py-2">Development</p>
            <div className="text-justify font-medium">
              <p>Elevate your enterprise through our API development services. Unlock unparalleled connectivity,
                 security and scalability tailored to your business needs. Partner with us for a sophisticated and seamless digital integration exeprience.
              </p>
            </div>
          </div>
          <div className="w-full shadow-xl flex-col p-4 rounded-lg hover:scale-105 hover:bg-sky-950 hover:text-white duration-300">
            <img className="w-20 mx-auto mt-[-3rem]" src={web} alt="/" />
            <h2 className="text-2xl font-bold text-center py-2">Web</h2>
            <p className="text-center text-xl font-bold py-2">Development</p>
            <div className="font-medium text-justify">
              <p>Revolutionize your online presence with our tech prowess! Expert web development services
                for a seamless,stunning, and user-friendly experience.
              </p>
            </div>
          </div>
          <div className="w-full shadow-xl flex-col p-4 rounded-lg hover:scale-105 hover:bg-sky-950 hover:text-white duration-300">
            <img className="w-20 mx-auto mt-[-3rem]" src={mobile} alt="/" />
            <h2 className="text-2xl font-bold text-center py-2">Mobile</h2>
            <p className="text-center text-xl font-bold py-2">Development</p>
            <div className="text-justify font-medium">
              <p>Drive innovation with our mobile development expertise. Crafting cutting-edge applications for IOS and Android, we blend sleek design with robust functionality.
                 Elevate your user experience and reach new heights in the mobile landscape. Partner with us to turn your app vision into reality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
