import React from 'react'
import Yunud from '../assets/yunus.jpg'
import Amina from '../assets/amina.jpg'
import Saif from '../assets/saif.jpg'
import Tahir from '../assets/tahir.jpg'
import Kola from '../assets/kola.JPG'



const Teammember = () => {
  return (
    <div className='w-full h-auto bg-sky-900 p-6'>
         <h1 className="text-white tracking-widest text-center pt-3">
      Our <span className="text-yellow-500">Team</span>{" "}
    </h1>
    <div className='flex flex-wrap mx-auto justify-center gap-1'>
        <div className='flex flex-col bg-sky-900 justify-center rounded-lg shadow-md w-full m-6 overflow-hidden md:w-[30%] hover:scale-105 hover:bg-sky-950 duration-300'>
            <img className='rounded-full h-[250px] w-[250px] px-2 m-6 self-center overflow-hidden' src={Yunud} alt="" />
            <h2 className='text-2xl text-center text-white px-2'>Muhammad Yunus Abu-Dukhan</h2>
            <p className='text-center font-semibold text-white px-2'>CTO</p>
            <p className='text-center text-white p-3 pt-2'>Lead: Backend Development</p>

        </div>

        <div className='flex flex-col bg-sky-900 justify-center rounded-lg shadow-md w-full m-6 overflow-hidden md:w-[30%] hover:scale-105 hover:bg-sky-950 duration-300'>
            <img className='rounded-full h-[250px] w-[250px] px-2 m-6 self-center overflow-hidden' src={Amina} alt="" />
            <h2 className='text-2xl text-center text-white px-2'>Amina Aminu</h2>
            <p className='text-center text-white p-3 pt-2'>Lead: UI/UX Design</p>

        </div>

        <div className='flex flex-col bg-sky-900 justify-center rounded-lg shadow-md w-full m-6 overflow-hidden md:w-[30%] hover:scale-105 hover:bg-sky-950 duration-300'>
            <img className='rounded-full h-[250px] w-[250px] m-6 px-2 self-center overflow-hidden' src={Saif} alt="" />
            <h2 className='text-2xl text-center text-white px-2'>Muhammad Saifudeen</h2>
            <p className='text-center text-white p-3 pt-2'>Project Manager</p>

        </div>

        <div className='flex flex-col bg-sky-900 justify-center rounded-lg shadow-md w-full m-6 overflow-hidden md:w-[30%] hover:scale-105 hover:bg-sky-950 duration-300'>
            <img className='rounded-full h-[250px] w-[250px] m-6 px-2 self-center overflow-hidden' src={Tahir} alt="" />
            <h2 className='text-2xl text-center text-white px-2'>Tahir Muhammad</h2>
            <p className='text-center text-white p-3 pt-2'>Lead: Mobile Development</p>

        </div> <div className='flex flex-col bg-sky-900 justify-center rounded-lg shadow-md w-full m-6 overflow-hidden md:w-[30%] hover:scale-105 hover:bg-sky-950 duration-300'>
            <img className='rounded-full h-[250px] w-[250px] m-6 px-2 self-center overflow-hidden' src={Kola} alt="" />
            <h2 className='text-2xl text-center text-white px-2'>Yahaya Saheed</h2>
            <p className='text-center text-white p-3 pt-2'>Lead: Web Development</p>

        </div>

        
      </div>
    </div>
  )
}

export default Teammember
