import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const About = () => {
  return (
    <div id="about" className="w-full h-auto p-5 text-center bg-sky-900 pt-20 mb-5 flex flex-col items-center justify-center">
      <h1 className="text-white tracking-widest">
        About <span className="text-yellow-500">Us</span>{" "}
      </h1>
      <div className="max-w-[1240px] md:flex md:justify-center md:gap-8 items-center">
        <div className="md:w-[70%] flex-col text-center">
          <h2 className="py-4 text-white">We are Touch I.T</h2>
          <p className="py-4  text-white text-justify">
          Touch IT is a dynamic firm dedicated to simplifying technology integration in communities. 
          Specializing in applications, APIs, and user-friendly software, we strive to enhance accessibility and usability. 
          Our team pioneers innovative solutions, leveraging the latest technology to streamline operations. Beyond development, 
          we are committed to empowering communities through education and support, envisioning a future where technology enriches lives. 
          Join us on our journey to create a more connected and digitally empowered world.
          </p>
          <div>
            <button className="bg-yellow-500 text-white font-medium w-[150px] hover:scale-105">
              <Link to="#chooseus" smooth>
                Read More
              </Link>
            </button>
          </div>
        </div>
        <div className="md:w-[50%] m-[40px] rounded-xl p-4 hover:scale-105 ease-in duration-300 hidden pb-5 md:block">
          <img
            className="rounded-xl w-[500px] h-[400px] object-cover"
            src="https://images.unsplash.com/photo-1525547719571-a2d4ac8945e2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bGFwdG9wfGVufDB8fDB8fHww"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default About;
